import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import { Formik, Form } from 'formik';
import { Views } from './Views';
import { Demography } from './Demography';
import { Targeting } from './Targeting';
import { Exclusions } from './Exclusions';
import { CampaignDuration } from './CampaignDuration';
import { GeneralInfo } from './GeneralInfo';
import { StyledContainer } from '../../components/StyledContainer';
import { InlineWrapper } from '../../components/InlineWrapper';
import { Routes } from '../../routes';
import { initialValues, ValidationSchema } from './formikConfig';
import { ICampaign } from '../Campaigns/types';
import { MutationFunctionOptions } from 'react-apollo';
import { ExecutionResult } from 'graphql';
import { CampaignUpdateInput } from './types';
import { CustomDialog } from '../../components/CustomDialog';
import moment from 'moment';

const useStyles = makeStyles({
  form: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& h5': {
      marginBottom: 30,
    },
  },
  button: {
    backgroundColor: '#ED1B30',
    color: '#fff',
    height: 56,
    width: 128,
  },
  headerNavlink: {
    display: 'flex',
    alignItems: 'center',
    color: '#333',
    textDecoration: 'none',
    '& h5': {
      marginBottom: 0,
      marginLeft: 20,
    },
  },
  buttonOutlined: {
    color: '#ED1B30',
    borderColor: '#ED1B30',
    height: 56,
    width: 128,
  },
});

interface IEditCampaign extends RouteComponentProps<{ id: string }> {
  campaign: ICampaign;
  updateCampaign: (
    options?: MutationFunctionOptions<CampaignUpdateInput, {}> | undefined
  ) => Promise<ExecutionResult<{}>>;
}

export const EditCampaign: React.FC<IEditCampaign> = ({
  campaign,
  updateCampaign,
  match,
  history,
}) => {
  const classes = useStyles();
  const [openErrorModal, setOpenErrorModal] = React.useState(false);

  const handleErrorModalClose = () => {
    setOpenErrorModal(false);
  };

  return (
    <StyledContainer>
      <NavLink className={classes.headerNavlink} to={`/${Routes.Campaigns}`}>
        <ArrowBackIcon />
        <Typography variant='h5' gutterBottom>
          Kampania
        </Typography>
      </NavLink>
      <Formik
        enableReinitialize
        initialValues={initialValues(campaign)}
        validationSchema={ValidationSchema}
        onSubmit={async (values) => {
          function toInt(key: string) {
            return values[key].map((a: string) => parseInt(a, 10));
          }
          const input: CampaignUpdateInput = {
            adPartnerId: values.adPartnerId,
            targetViewsNumber: values.targetViewsNumber,
            maxViewsPerViewer: parseInt(values.maxViewsPerViewer, 10),
            cpm: parseInt(values.cpm, 10),
            startDate: moment(values.startDate).toISOString(),
            stopDate: moment(values.stopDate).toISOString(),
            priority: values.priority,
            isActive: values.isActive === 'true',
            categoriesPositive: toInt('categoriesPositive'),
            categoriesNegative: toInt('categoriesNegative'),
            genresPositive: toInt('genresPositive'),
            genresNegative: toInt('genresNegative'),
            tagsPositive: toInt('tagsPositive'),
            tagsNegative: toInt('tagsNegative'),
            ageRanges: toInt('ageRanges'),
            genders: values['genders'],
            // parentalStatuses: toInt('parentalStatuses'),
          };

          try {
            await updateCampaign({
              variables: {
                where: { id: match.params.id },
                input,
              },
            });
            history.push(`/${Routes.Campaigns}`);
          } catch (error) {
            setOpenErrorModal(true);
          }
        }}
      >
        {({ submitForm, isSubmitting }) => (
          <Form autoComplete='off' noValidate className={classes.form}>
            <GeneralInfo isAssetBlocked={campaign.isAssetBlocked} />
            <Views />
            <Demography />
            <Targeting />
            <Exclusions />
            <CampaignDuration />
            <InlineWrapper>
              <Button
                variant='outlined'
                className={classes.buttonOutlined}
                component={NavLink}
                to={`/${Routes.Campaigns}`}
                disabled={isSubmitting}
              >
                Anuluj
              </Button>
              <Button
                onClick={() => submitForm()}
                variant='contained'
                className={classes.button}
                disabled={isSubmitting}
              >
                Zapisz
              </Button>
            </InlineWrapper>
          </Form>
        )}
      </Formik>
      <CustomDialog
        isModal
        open={openErrorModal}
        handleClose={handleErrorModalClose}
        title='Ups!'
        description='Wystąpił błąd serwera, lub utraciłeś połączenie z internetem.'
      />
    </StyledContainer>
  );
};

export default EditCampaign;
