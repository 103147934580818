import React from 'react';
//@ts-ignore
import { PriorityType } from '../../../Campaigns/types.d.ts';
import Radio, { Option } from './Radio';

const options: Option[] = [
  { id: PriorityType.NORMAL.toString(), name: 'Normalny' },
  { id: PriorityType.ASAP.toString(), name: 'Wysoki' }
];

function Priority(props: { label: string; fieldName: string }) {
  return <Radio {...props} options={options} />;
}

export default Priority;
