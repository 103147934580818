import { makeStyles } from '@material-ui/core/styles';

export const GeneralClasses = makeStyles({
  button: {
    backgroundColor: '#ED1B30',
    color: '#fff',
    height: 56,
    minWidth: 128,
  },
  buttonOutlined: {
    color: '#ED1B30',
    borderColor: '#ED1B30',
    height: 56,
    minWidth: 128,
  },
  styledInput: {
    marginBottom: 30,
    width: 256,
  },
  inlineParagraph: {
    whiteSpace: 'nowrap'
  }
});

export default GeneralClasses;
