import * as firebase from 'firebase/app';
import { SetStateAction } from 'react';

interface IValues {
  email: string;
  oldPassword: string;
  newPassword: string;
}

interface ICallbacks {
  onVeryficationError: (
    error: SetStateAction<{ code: string; message: string } | null>
  ) => void;
  onError: () => void;
  onSuccess: () => void;
}

export function changePassword(values: IValues, callbacks: ICallbacks) {
  const user = firebase.auth().currentUser;
  const credential = firebase.auth.EmailAuthProvider.credential(
    values.email,
    values.oldPassword
  );

  if (!user) return;
  user
    .reauthenticateAndRetrieveDataWithCredential(credential)
    .then(function() {
      user
        .updatePassword(values.newPassword)
        .then(callbacks.onSuccess)
        .catch(callbacks.onError);
    })
    .catch(function(error) {
      callbacks.onVeryficationError(error);
    });
}
