import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PeopleIcon from '@material-ui/icons/People';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ListAltIcon from '@material-ui/icons/ListAlt';
import { NavLink, withRouter } from 'react-router-dom';
import { Routes } from '../routes';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => createStyles({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  activeRoute: {
    color: '#ED1B30',
    '& svg': {
      color: '#ED1B30',
    },
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  toolbar: theme.mixins.toolbar,
}));

const StyledDrawer: React.FC<{location: any}> = ({ location }) => {
  const classes = useStyles();

  if (location.pathname.match(RegExp(Routes.Login)) || location.pathname === '/') {
    return null;
  }

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
    >
      <div className={classes.toolbar} />
      <List>
        <ListItem button key="Kampanie" component={NavLink} activeClassName={classes.activeRoute} to={`/${Routes.Campaigns}`}>
          <ListItemIcon><ListAltIcon /></ListItemIcon>
          <ListItemText primary="Kampanie" />
        </ListItem>
        <ListItem button key="Partnerzy" component={NavLink} activeClassName={classes.activeRoute} to={`/${Routes.AdPartners}`}>
          <ListItemIcon><PeopleIcon /></ListItemIcon>
          <ListItemText primary="Partnerzy" />
        </ListItem>
        <ListItem button key="Moje konto" component={NavLink} activeClassName={classes.activeRoute} to={`/${Routes.Account}`}>
          <ListItemIcon><AccountCircleIcon /></ListItemIcon>
          <ListItemText primary="Moje konto" />
        </ListItem>
      </List>
    </Drawer>
  );
};

export const DrawerWithLogic = withRouter(StyledDrawer);

export default DrawerWithLogic;
