import Typography from '@material-ui/core/Typography';
import React from 'react';
import { AgeRanges, Genders } from './SelectorsWithData';

export const Demography = () => (
  <>
    <Typography variant="h5" gutterBottom>
      Demografia
    </Typography>
    <AgeRanges fieldName="ageRanges" label="Wiek" />
    <Genders fieldName="genders" label="Płeć" />
  </>
);

export default Demography;
