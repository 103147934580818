import * as yup from 'yup';

const ERROR_REQUIRED = 'Dodaj plik!';
const ERROR_FILE_SIZE = 'Zbyt duży rozmiar pliku!';
const ERROR_FILE_FORMAT = 'Nieprawidłowy format pliku!';
const FILE_SIZE = 2097152;
const SUPPORTED_FORMATS = ['text/csv'];

export const ValidationSchema = yup.object().shape({
  file: yup
    .mixed()
    .test('fileSize', ERROR_FILE_SIZE, (value) =>
      value ? value.size <= FILE_SIZE : true
    )
    .test('fileFormat', ERROR_FILE_FORMAT, (value) =>
      value ? SUPPORTED_FORMATS.includes(value.type) : true
    )
    .required(ERROR_REQUIRED),
});
