import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';

const CREATE_AD_PARTNER = gql`
  mutation CreateAdPartner($name: String!, $notes: String! ) {
    createAdPartner(data: {name: $name, notes: $notes}) {
      id
      name
      notes
    }
  }
`;

function withCreateAdPartner(
  WrappedComponent: React.FC<{createAdPartnerMutation: any}>,
) {
  return (props: {}) => {
    const [createAdPartner] = useMutation(CREATE_AD_PARTNER, {
      refetchQueries: 
        ['Adpartners']
    });

    return <WrappedComponent {...props} createAdPartnerMutation={createAdPartner} />;
  };
}

export default withCreateAdPartner;
