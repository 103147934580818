import DateFnsUtils from '@date-io/date-fns';
import Typography from '@material-ui/core/Typography';
import {
  KeyboardDateTimePicker as MuiKeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Field } from 'formik';
import {
  KeyboardDateTimePickerProps,
  fieldToKeyboardDateTimePicker,
} from 'formik-material-ui-pickers';
import React from 'react';

export function KeyboardDateTimePicker({
  children,
  form: { setFieldError, ...form },
  ...props
}: KeyboardDateTimePickerProps) {
  return (
    <MuiKeyboardDateTimePicker
      {...fieldToKeyboardDateTimePicker({
        ...props,
        form: { ...form, setFieldError: () => null },
      })}
    >
      {children}
    </MuiKeyboardDateTimePicker>
  );
}

export const CampaignDuration = () => (
  <>
    <Typography variant="h5" gutterBottom>
      Czas kampanii
    </Typography>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <Field
        component={KeyboardDateTimePicker}
        margin="normal"
        label="Data startu"
        format="dd-MM-yyyy HH:mm"
        name="startDate"
        ampm={false}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
      <Field
        component={KeyboardDateTimePicker}
        margin="normal"
        label="Data zakończenia"
        name="stopDate"
        format="dd-MM-yyyy HH:mm"
        ampm={false}
        KeyboardButtonProps={{
          "aria-label": "change date",
        }}
      />
    </MuiPickersUtilsProvider>
  </>
);

export default CampaignDuration;
