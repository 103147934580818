import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { Field, FieldProps } from 'formik';
import { fieldToTextField } from 'formik-material-ui';
import _ from 'lodash';
import React from 'react';
import GeneralClasses from '../../../../components/GeneralClasses';

export type Option = {
  id: string;
  name: string;
};

interface IAc extends FieldProps {
  label: string;
  options: Option[];
}

const FormikAutocomplete = ({ label, options, ...props }: IAc) => {
  const {
    form: { setTouched, setFieldValue }
  } = props;

  const { error, helperText, value, ...field } = fieldToTextField(props);
  const { name } = field;

  const selectedMap = (value as number[])?.map(id => ({ id })) ?? [];
  const selectedObjects = _.intersectionBy(options, selectedMap, 'id');

  const handleChange = (
    _: React.ChangeEvent<{}>,
    value: unknown | unknown[]
  ): void =>
    setFieldValue(
      name || '',
      (value as Option[]).map(({ id }) => id)
    );

  return (
    //@ts-ignore
    <Autocomplete
      {...props}
      {...field}
      autoComplete
      multiple
      options={options}
      value={selectedObjects}
      onChange={handleChange}
      //@ts-ignore
      onBlur={() => setTouched({ [name]: true })}
      getOptionLabel={(option: Option) => option.name}
      renderInput={props => (
        <TextField
          {...props}
          helperText={helperText}
          error={error}
          variant="outlined"
          label={label}
          fullWidth
          className={GeneralClasses().styledInput}
        />
      )}
    />
  );
};

function Autocompletes({
  options,
  label,
  fieldName
}: {
  options?: Option[];
  label?: string;
  fieldName: string;
}) {
  if (!options) return null;

  return (
    <Field
      multiple
      name={fieldName}
      size="small"
      options={options}
      label={label}
      component={FormikAutocomplete}
    />
  );
}

export default Autocompletes;
