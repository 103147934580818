import withAdPartners from './withAdPartners';
import { AdPartners as View } from './View';
import withDeleteAdPartner from './withDeleteAdPartner';
import withPagination from '../../utils/pagination';

const initialOrder = 'name';
const initialSortingOrder = 'desc';

const AdPartners = withPagination(
  initialOrder,
  initialSortingOrder
)(withAdPartners(withDeleteAdPartner(View)));

export default AdPartners;
