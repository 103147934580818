import React, { useState, SetStateAction } from 'react';
import { TextField } from 'formik-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { StyledContainer } from '../components/StyledContainer';
import { InlineWrapper } from '../components/InlineWrapper';
import { GeneralClasses } from '../components/GeneralClasses';
import { Routes } from '../routes';
import { NavLink, useHistory } from 'react-router-dom';
import { logout } from '../session/logout';
import { CustomDialog } from '../components/CustomDialog';
import { changePassword } from '../session/changePassword';
import CustomizedProgressBar from '../components/Loader';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';

const useStyles = makeStyles({
  form: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start'
  },
  button: {
    backgroundColor: '#ED1B30',
    color: '#fff',
    height: 56,
    width: 128
  },
  buttonOutlined: {
    color: '#ED1B30',
    borderColor: '#ED1B30',
    height: 56,
    width: 128
  },
  errorMessage: {
    color: '#ED1B30',
  }
});

const ChangePasswordSchema = yup.object().shape({
  email: yup
    .string()
    .email()
    .required(),
  oldPassword: yup
    .string()
    .required(),
  newPassword: yup
    .string()
    .min(6)
    .max(16)
    .required(),
});

const Account: React.FC  = () => {
  const classes = useStyles();
  const [isLoading, setLoading] = useState(false);
  const history = useHistory()
  const [openSuccessModal, setOpenSuccessModal] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [veryficationError, setVeryficationError] = useState<{
    code: string;
    message: string;
  } | null>(null);
  const genralClasses = GeneralClasses();

  const handleLogout = () => {
    setLoading(true);
    logout();
  };

  const customVeryficationErrorMessage = (veryficationError: {code: string, message: string}) => {
    switch (veryficationError.code) {
      case 'auth/wrong-password':
        return 'Stare hasło jest niepoprawne';
      case 'auth/user-not-found':
        return 'Niepoprawna nazwa użytkownika';
      case 'auth/too-many-requests':
        return 'Zbyt dużo prób. Spróbuj ponownie później';
      case 'auth/network-request-failed':
        return 'Problem z połączeniem';
      default:
        return veryficationError.message;
    }
  }

  const handleErrorModalClose = () => {
    setOpenErrorModal(false);
  };

  const handleSuccessModalClose = () => {
    setOpenSuccessModal(false);
    history.push(`/${Routes.AdPartners}`);
  };

  const changePasswordCallbacks = {
    onVeryficationError: (error: SetStateAction<{ code: string; message: string; } | null>) => setVeryficationError(error),
    onError: () => setOpenErrorModal(true),
    onSuccess: () => setOpenSuccessModal(true)
  }

  return (
    <>
    {isLoading && <CustomizedProgressBar />}
    <StyledContainer>
      <Typography variant='h5' gutterBottom>
        Moje konto
      </Typography>
      <Formik
        initialValues= {{
          email: '',
          oldPassword: '',
          newPassword: '',
        }}
        validationSchema={ChangePasswordSchema}
        onSubmit={async (values) => {
          try {
            await changePassword(
              values,
              changePasswordCallbacks
            );
          } catch (error) {
            setVeryficationError(error);
          }
        }}
      >
        <Form className={classes.form}>
          <Field
            component={TextField}
            type='email'
            name='email'
            label='E-mail'
            variant='outlined'
            className={genralClasses.styledInput}
          />
          <Field
            component={TextField}
            type='password'
            name='oldPassword'
            label='Stare hasło'
            variant='outlined'
            className={genralClasses.styledInput}
          />
          <Field
            component={TextField}
            type='password'
            name='newPassword'
            label='Nowe hasło'
            variant='outlined'
            className={genralClasses.styledInput}
          />
          <Typography className={classes.errorMessage} variant='body1' gutterBottom>
            {veryficationError && customVeryficationErrorMessage(veryficationError)}
          </Typography>
          <InlineWrapper>
            <Button component={NavLink} to={`/${Routes.Campaigns}`} variant='outlined' className={classes.buttonOutlined}>
              Anuluj
            </Button>
            <Button variant='contained' type="submit" className={classes.button}>
              Zapisz
            </Button>
          </InlineWrapper>
          <Button onClick={handleLogout}>
            Wyloguj
          </Button>
        </Form>
      </Formik>
      <CustomDialog isModal open={openSuccessModal} handleClose={handleSuccessModalClose} title="Sukces!" description="Twoje hasło zostało zmienione" />
      <CustomDialog isModal open={openErrorModal} handleClose={handleErrorModalClose} title="Ups!" description="Wystąpił błąd, lub utraciłeś połączenie z internetem." />
    </StyledContainer>
    </>
  );
};

export default Account;
