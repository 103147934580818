import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#191B19"
    },
    secondary: {
      main: "#ED1B30"
    },
    background: {
      default: "#fff"
    }
  }
});

export default theme;
