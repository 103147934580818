import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Categories, Genres, Tags } from './SelectorsWithData';
// import ParentalStatuses from './SelectorsWithData/Checkbox/ParentialStatuses';

export const Exclusions = () => (
  <>
    <Typography variant="h5" gutterBottom>
      Wykluczenia
    </Typography>
    <Categories label="Kategoria" fieldName="categoriesNegative" />
    <Genres label="Gatunek" fieldName="genresNegative" />
    <Tags label="Tagi" fieldName="tagsNegative" />
    {/* <ParentalStatuses
      label="Status rodzicielski"
      fieldName="parentalStatuses"
    /> */}
  </>
);

export default Exclusions;
