import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
  styledWrapper: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: 30,
    '& div:not(:first-child), & button:not(:first-child), & a:not(:first-child)': {
      marginLeft: 20,
    },
    '&:not(:first-child)': {
      marginTop: 50,
    },
  },
});

export const InlineWrapper: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.styledWrapper}>
      {children}
    </div>
  );
};

export default InlineWrapper;
