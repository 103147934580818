import React from 'react';
import { TextField, IconButton, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';

interface SearchFieldProps {
  searchValue: string;
  onChange: ({
    target: { value },
  }: React.ChangeEvent<HTMLInputElement>) => void;
}

export const SearchField: React.FC<SearchFieldProps> = ({
  searchValue,
  onChange,
}) => {
  const classes = useStyles();

  return (
    <form className={classes.root} noValidate autoComplete='off'>
      <IconButton
        type='submit'
        className={classes.iconButton}
        aria-label='search'
      >
        <SearchIcon />
      </IconButton>
      <Divider className={classes.divider} orientation='vertical' />
      <TextField
        id='outlined-search'
        label='Search'
        type='search'
        variant='outlined'
        value={searchValue}
        onChange={onChange}
      />
    </form>
  );
};

const useStyles = makeStyles(() => ({
  root: {
    position: 'absolute',
    right: '40px',
    '& .MuiTextField-root': {
      width: '280px',
    },
    '& .MuiInputLabel-outlined': {
      paddingLeft: 50,
    },
    '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
      paddingLeft: 0,
    },
    '& .MuiInputBase-input': {
      paddingLeft: 58,
    },
    '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl, & .MuiFormControl-root.MuiTextField-root': {
      marginLeft: 0,
    },
  },
  iconButton: {
    position: 'absolute',
    zIndex: 1,
    left: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  divider: {
    position: 'absolute',
    left: 48,
    height: 28,
    top: '50%',
    transform: 'translateY(-50%)',
  },
}));
