import React from 'react';
import Radio, { Option } from './Radio';

const options: Option[] = [
  { id: 'true', name: 'Tak' },
  { id: 'false', name: 'Nie' },
];

function IsActive(props: {
  label: string;
  fieldName: string;
  disabled: boolean;
}) {
  return <Radio {...props} options={options} />;
}

export default IsActive;
