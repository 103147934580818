import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';
import {
  FormControl,
  InputLabel,
  makeStyles,
  createStyles,
  MenuItem
} from '@material-ui/core';
import { Field } from 'formik';
import { Select } from 'formik-material-ui';
import { Option } from './Autocompletes/Autocomplete';

const GET_AD_PARTNERS = gql`
  query AdPartners {
    adPartners {
      name
      id
    }
  }
`;

interface IAdPartners {
  adPartners?: Option[];
}

const useStyles = makeStyles(theme =>
  createStyles({
    select: {
      width: 256
    },
    formControl: {
      margin: theme.spacing(3),
      marginLeft: 0
    }
  })
);

function AdPartners() {
  const classes = useStyles();
  const { data } = useQuery<IAdPartners, {}>(GET_AD_PARTNERS);
  const { adPartners } = data ?? { };

  if (!adPartners) return null;

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="partner-select-label">Partner</InputLabel>
      <Field
        component={Select}
        labelId="partner-select-label"
        name="adPartnerId"
        className={classes.select}
      >
        {adPartners.map(({ id, name }) => (
          <MenuItem key={id} value={id}>{name}</MenuItem>
        ))}
      </Field>
    </FormControl>
  );
}

export default AdPartners;
