import React, { Component } from 'react';
import qs from 'qs';
import _ from 'lodash';
import { SearchField } from './SearchField';
import { RouteComponentProps } from 'react-router-dom';
import { withRouter } from 'react-router-dom';


type SearchState = {
  searchValue: string;
}

type PropsType = RouteComponentProps & {
  refetchQuery: (variables?: {} | undefined) => void;
}

class SearchFieldContainer extends Component<PropsType, SearchState> { 
  constructor(props: PropsType) {
    super(props)
    this.setState({
      searchValue: ''
    });
    this.handleChange = this.handleChange.bind(this);
  }

  saveDebounced = _.debounce(() => {
    const { history } = this.props;
    const locationSearch = qs.parse(history.location.search, {
      ignoreQueryPrefix: true,
    });

    const { page } = locationSearch;
    this.props.refetchQuery();

    let queryArgs = {...locationSearch};

    if (page && page !== '0') {
      queryArgs = {...queryArgs, page: '0'};
    }

    if (this.state.searchValue && this.state.searchValue.length > 0) {
      queryArgs = {...queryArgs, searchByNamePhrase: this.state.searchValue};
    } else {
      delete queryArgs['searchByNamePhrase'];
    }

    history.replace({
      ...history.location,
      search: qs.stringify(queryArgs)
    });
  }, 300);

  handleChange ({target: { value }}: React.ChangeEvent<HTMLInputElement>) {
    this.setState(
      {...this.state, searchValue: value}, 
      () => this.saveDebounced());
  };

  render() {
    return <SearchField searchValue={this.state ? this.state.searchValue : ''} onChange={this.handleChange} />;
  }
};

export default withRouter(SearchFieldContainer);