import React from 'react';
import { gql } from 'apollo-boost';
import { useMutation } from '@apollo/react-hooks';
import {DELETE_AD_PARTNER} from '../AdPartners/withDeleteAdPartner';

const EDIT_AD_PARTNER = gql`
  mutation UpdateAdPartner($id: String!, $name: String!, $notes: String!) {
    updateAdPartner(where:{id: $id}, data: {name: $name, notes: $notes}) {
      id
      name
      notes
    }
  }
`;

function withEditAdPartner(
  WrappedComponent: React.FC<{editAdPartnerMutation: any, deleteAdPartnerMutation: any}>,
) {
  return (props: {}) => {
    const [editAdPartner] = useMutation(EDIT_AD_PARTNER);
    const [deleteAdPartner] = useMutation(DELETE_AD_PARTNER, {
      refetchQueries: ['AdPartners']
    });
    return <WrappedComponent {...props} editAdPartnerMutation={editAdPartner} deleteAdPartnerMutation={deleteAdPartner}/>;
  };
}

export default withEditAdPartner;
