import gql from 'graphql-tag';
import { withParsedData } from '../withParsedData';
import Checkbox from './Checkbox';

const GET_PARENTIAL_STATUSES = gql`
  query {
    options: parentalStatuses {
      id
      name
    }
  }
`;


const ParentalStatuses = withParsedData({ QUERY: GET_PARENTIAL_STATUSES, Component: Checkbox });

export default ParentalStatuses;
