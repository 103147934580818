import React, { PropsWithChildren } from 'react';
import { gql, ApolloQueryResult } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { IAdPartner } from './types';
import { PaginationProps } from '../../utils/pagination';


const GET_AD_PARTNERS = gql`
  query AdPartners($input: AdPartnersWhereInput!, $searchByName: String) {
    adPartners(where: $input, searchByNamePhrase: $searchByName) {
      id
      name
      notes
      campaignsNumber
      campaigns {
        campaignId
      }
    }
    countAdPartners(searchByNamePhrase: $searchByName)
  }
`;

interface IAdPartnersData {
  adPartners: IAdPartner[];
  countAdPartners?: number;
  loading?: boolean;
}

export interface IWithAdPartner extends IAdPartnersData {
  refetch: (
    variables?: {} | undefined
  ) => Promise<ApolloQueryResult<IAdPartnersData>>;
}

function withAdPartners(
  WrappedComponent: React.FC<IWithAdPartner & PaginationProps>
) {
  return (props: PropsWithChildren<PaginationProps>) => {
    const { loading, error, data, refetch } = useQuery<IAdPartnersData, {}>(
      GET_AD_PARTNERS,
      { variables: {
        input: {
          orderBy: props.orderBy,
          order: props.order,
          offset: props.rowsPerPage * props.page,
          first: props.rowsPerPage,
        },
        searchByName: props.searchByNamePhrase
      },
        fetchPolicy: 'cache-and-network',
      }
    );

    if (error) return <p>Wystąpił Błąd</p>;

    const countAdPartners = data?.countAdPartners ?? 0;
    const adPartners = data?.adPartners ?? [];

    return (
      <WrappedComponent {...props} 
        adPartners={adPartners}
        refetch={refetch} 
        totalRows={countAdPartners}
        loading={loading} />
    );
  };
}

export default withAdPartners;
