import React, { PropsWithChildren } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { MutationFunctionOptions, ExecutionResult } from 'react-apollo';
import { IWithAdPartner } from './withAdPartners';
import { PaginationProps } from '../../utils/pagination';

export const DELETE_AD_PARTNER = gql`
  mutation DeleteAdPartner($id: String!) {
    deleteAdPartner(where:{id: $id}) {
      id
      name
      notes
    }
  }
`;

export interface IAdPartnerProps extends IWithAdPartner {
  deleteAdPartner: (
    options?: MutationFunctionOptions<any, Record<string, any>>
  ) => Promise<ExecutionResult<any>>;
}

function withDeleteAdPartner(
  WrappedComponent: React.FC<IAdPartnerProps & PaginationProps>
) {
  return (props: PropsWithChildren<IWithAdPartner & PaginationProps>) => {
    const [deleteAdPartner] = useMutation(DELETE_AD_PARTNER, {
      refetchQueries: ['AdPartners'],
    });
    return <WrappedComponent {...props} deleteAdPartner={deleteAdPartner} />;
  };
}

export default withDeleteAdPartner;
