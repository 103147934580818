import { useQuery } from '@apollo/react-hooks';
import { DocumentNode } from 'graphql';
import React from 'react';
import { Option } from './Checkbox/Checkbox';
import { OptionsType } from './types';

function defaultParser({ id, name }: OptionsType) {
  return ({
    id,
    name
  }) as Option;
}

type WithParsedDataProps = {
  QUERY: DocumentNode;
  parser?: (option: OptionsType) => Option;
  Component: React.FC<{
    options?: Option[] | undefined;
    label?: string | undefined;
    fieldName: string;
  }>
};

export function withParsedData({
  QUERY,
  parser = defaultParser,
  Component
}: WithParsedDataProps) {
  return function(props: { label: string; fieldName: string }) {
    const { data } = useQuery<{ options?: OptionsType[] }, {}>(QUERY);
    const { options } = data ?? { options: [] };

    const optionsParsed = options?.map(parser);

    return <Component {...props} options={optionsParsed} />;
  };
}
