import React from 'react';
import Typography from '@material-ui/core/Typography';
import { GeneralClasses } from '../../components/GeneralClasses';
import { Field } from 'formik';
import { TextField } from 'formik-material-ui';

export const Views = () => {
  const { styledInput } = GeneralClasses();
  return (
    <>
      <Typography variant="h5" gutterBottom>
        Wyświetlenia
      </Typography>
      <Field
        component={TextField}
        name="targetViewsNumber"
        type="number"
        label="Wyświetlenia cel"
        variant="outlined"
        className={styledInput}
      />
      <Field
        component={TextField}
        name="cpm"
        label="CPM"
        variant="outlined"
        className={styledInput}
      />
      <Field
        component={TextField}
        name="maxViewsPerViewer"
        label="Capping"
        variant="outlined"
        className={styledInput}
      />
    </>
  );
};

export default Views;
