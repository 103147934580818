import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, InputLabel } from '@material-ui/core';
import { FormikProps } from 'formik';

export interface EnhancedFile extends File {
  fileSize?: number;
  fileType?: string;
}

type IUploadFileButton = Omit<
  FormikProps<{ file?: EnhancedFile }>,
  'submitForm'
>;

const useStyles = makeStyles({
  uploadFileButtonContainer: {
    position: 'relative',
  },
  buttonOutlined: {
    color: '#ED1B30',
    borderColor: '#ED1B30',
    height: 56,
    width: 128,
    marginLeft: '0 !important',
  },
  label: {
    position: 'absolute',
    top: -90,
    width: 420,
    lineHeight: '20px',
  },
  fileName: {
    position: 'absolute',
    top: -25,
    width: 420,
    fontSize: '0.8rem',
  },
  fileError: {
    color: '#ED1B30',
  },
});

export const UploadFileButton: React.FC<IUploadFileButton> = ({
  setFieldValue,
  values,
  errors,
}) => {
  const classes = useStyles();
  const fileInputRef = React.createRef() as React.RefObject<HTMLInputElement>;
  const fileName = values.file ? values.file.name : undefined;
  const fileError = errors ? errors.file : undefined;

  const handleButtonClick = () => {
    if (!fileInputRef.current) {
      return;
    }
    fileInputRef.current.click();
  };

  const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (!event.currentTarget.files) {
      return;
    }
    setFieldValue('file', event.currentTarget.files[0]);
  };

  return (
    <div className={classes.uploadFileButtonContainer}>
      <InputLabel id='file' className={classes.label}>
        Wymagany format csv.
        <br /> Maksymalny rozmiar pliku 2MB.
      </InputLabel>
      {(fileName || fileError) && (
        <span className={classes.fileName}>
          {fileName} <span className={classes.fileError}>{fileError}</span>
        </span>
      )}
      <input
        style={{ display: 'none' }}
        ref={fileInputRef}
        type='file'
        accept='.csv'
        name='file'
        id='file'
        onChange={handleUploadFile}
      />
      <Button
        type='button'
        variant='outlined'
        className={classes.buttonOutlined}
        onClick={handleButtonClick}
      >
        Załącz plik
      </Button>
    </div>
  );
};

export default UploadFileButton;
