import Typography from '@material-ui/core/Typography';
import React from 'react';
import { Categories, Genres, Tags } from './SelectorsWithData';

export const Targeting = () => (
  <>
    <Typography variant="h5" gutterBottom>Targetowanie</Typography>
    <Categories label="Kategoria" fieldName="categoriesPositive" />
    <Genres label="Gatunek" fieldName="genresPositive" />
    <Tags label="Tagi" fieldName="tagsPositive" />
  </>
);

export default Targeting;
