import React, { PropsWithChildren } from 'react';
import qs from 'qs';
import { useHistory } from 'react-router-dom';

export const ITEMS_PER_PAGE = 8;

export const paginationVariables = ({
  currentPage,
  rowsPerPage,
}: {
  currentPage: number;
  rowsPerPage: number;
}) => {
  const first = rowsPerPage || ITEMS_PER_PAGE;
  const offset = (currentPage || 0) * first - first;

  return {
    first,
    offset,
  };
};

export interface PaginationProps {
  orderBy: string;
  onSortColumn: (newOrderBy: string) => void;
  order: 'desc' | 'asc';
  rowsPerPage: number;
  page: number;
  searchByNamePhrase?: string;
  totalRows?: number;
  onChangeRowsPerPage?: (target: any) => void;
  onChangePage: (
    event: React.MouseEvent<HTMLButtonElement> | null,
    value: number
  ) => void;
}

const withPagination = (
  initialOrder: string,
  initialSortingOrder: 'desc' | 'asc'
) => (WrappedComponent: any) => {
  return (props: PropsWithChildren<{}>) => {
    const history = useHistory();

    const handleSortColumn = (newOrderBy: string) => {
      const { rowsPerPage, order } = decodeUrl();
      const newSortingOrder = order === 'asc' ? 'desc' : 'asc';

      const query = qs.stringify({
        orderBy: newOrderBy,
        order: newSortingOrder,
        rowsPerPage,
      });
      history.replace({
        ...history.location,
        search: query,
      });
    };

    const decodeUrl = () => {
      const { rowsPerPage, page, orderBy, order, searchByNamePhrase } = qs.parse(
        history.location.search,
        { ignoreQueryPrefix: true }
      );
      return {
        rowsPerPage,
        page,
        orderBy,
        order,
        searchByNamePhrase
      };
    };

    const handleChangeRowsPerPage = ({ target: { value } }: any) => {
      const pagination = {
        rowsPerPage: value,
        page: 0,
      };
      const query = qs.stringify(
        { ...pagination, orderBy },
        { arrayFormat: 'brackets' }
      );
      history.replace({
        ...history.location,
        search: query,
      });
    };

    const handleChangePage = (
      event: React.MouseEvent<HTMLButtonElement> | null,
      value: number
    ) => {
      const pagination = {
        rowsPerPage,
        order,
        page: value,
      };

      const query = qs.stringify({ ...pagination, orderBy, searchByNamePhrase });
      history.replace({
        ...history.location,
        search: query,
      });
    };

    const { rowsPerPage, page, orderBy, order, searchByNamePhrase } = decodeUrl();

    return (
      <WrappedComponent
        onSortColumn={handleSortColumn}
        orderBy={orderBy || initialOrder}
        order={order || initialSortingOrder}
        rowsPerPage={parseInt(String(rowsPerPage || ITEMS_PER_PAGE), 10)}
        searchByNamePhrase={searchByNamePhrase}
        page={parseInt(String(page || 0), 10)}
        onChangeRowsPerPage={handleChangeRowsPerPage}
        onChangePage={handleChangePage}
        {...props}
      />
    );
  };
};
export default withPagination;
