import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField } from 'formik-material-ui';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import background from '../assets/background.png';
import { Formik, Form, Field } from 'formik';
import * as yup from 'yup';
import { history } from '../App';
import CustomizedProgressBar from '../components/Loader';

const useStyles = makeStyles({
  loginPageWrapper: {
    display: 'flex',
    height: '100%'
  },
  loginFormWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: 440
  },
  loginFormContainer: {
    width: 256
  },
  loginForm: {
    width: 256,
    display: 'flex',
    flexDirection: 'column',
    '& >a, >div, >input, >button': {
      marginTop: 30
    }
  },
  backgroundImageWrapper: {
    backgroundImage: `url(${background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    width: '100%',
    position: 'relative'
  },
  backgroundImageOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    backgroundColor: 'rgba(224, 15, 44, .67)'
  },
  button: {
    backgroundColor: '#ED1B30',
    color: '#fff',
    height: 56,
    width: 128
  },
  errorMessage: {
    color: '#ED1B30',
    marginTop: 20
  }
});

  const LoginSchema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required(),
    password: yup
      .string()
      .min(6)
      .max(16)
      .required(),
  });

export const Login = () => {
  const classes = useStyles();
  const [loginError, setLoginError] = useState<{
    code: string;
    message: string;
  } | null>(null);
  const [isLoading, setLoading] = useState(false);

  const customLoginErrorMessage = (loginError: {code: string, message: string}) => {
    switch (loginError.code) {
      case 'auth/wrong-password':
        return 'Hasło jest niepoprawne, lub użytkownik nie posiada hasła';
      case 'auth/user-not-found':
        return "Użytkownik o podanym e-mailu nie istnieje, lub został usunięty";
      case 'auth/too-many-requests':
        return "Zbyt dużo prób logowania. Spróbuj ponownie później";
      case 'auth/network-request-failed':
        return "Problem z połączeniem";
      default:
        return loginError.message;
    }
  }

  const signInAndGetSession = async (email: string, passoword: string) => {
    firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
    const result = await
      firebase.auth()
      .signInWithEmailAndPassword(email, passoword);
    const idToken = await result.user?.getIdToken();
  
    if (!idToken) {
      throw new Error(
        'IdToken is missing! IdToken is required for setting session cookie.'
      );
    }
  
    if (!process.env.REACT_APP_API) {
      throw new Error('API IRL is required!');
    }
  
    await fetch(`${process.env.REACT_APP_API}/user/session`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ idToken })
    });
  };

  return (
    <div className={classes.loginPageWrapper}>
      <div className={classes.loginFormWrapper}>
        <div className={classes.loginFormContainer}>
          <Typography variant='h5' gutterBottom>
            Witaj na platformie Festival Group
          </Typography>
          <Typography variant='subtitle1' gutterBottom>
            Zaloguj się, by zobaczyć więcej informacji
          </Typography>
          <Formik
            initialValues= {{
              email: '',
              password: '',
            }}
            validationSchema={LoginSchema}
            onSubmit={async(values, { setSubmitting }) => {
              setLoading(true);
              try {
                await signInAndGetSession(values.email, values.password);
                setLoading(false);
                history.push('/ad-partners');
              } catch (error) {
                setLoading(false);
                setLoginError(error);
              }
            }}
          >
          <Form className={classes.loginForm}>
            <Field
              component={TextField}
              type='text'
              name='email'
              placeholder='email'
              label='E-mail'
              variant='outlined'
            />
            <Field
              component={TextField}
              type='password'
              name='password'
              placeholder='password'
              label='Hasło'
              variant='outlined'
            />
            {
              isLoading && <CustomizedProgressBar />
            }
            <Button
              variant='contained'
              className={classes.button}
              type='submit'
            >
              Zaloguj się
            </Button>
          </Form>
          </Formik>
          <Typography className={classes.errorMessage} variant='body1' gutterBottom>
            {loginError && customLoginErrorMessage(loginError)}
          </Typography>
        </div>
      </div>
      <div className={classes.backgroundImageWrapper}>
        <div className={classes.backgroundImageOverlay} />
      </div>
    </div>
  );
};


