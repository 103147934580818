import gql from 'graphql-tag';
import { withParsedData } from '../withParsedData';
import Autocompletes from './Autocomplete';

const GET_CATEGORIES = gql`
  query {
    options: categories {
      id
      name
    }
  }
`;

const Categories = withParsedData({
  QUERY: GET_CATEGORIES,
  Component: Autocompletes
});

export default Categories;
