import React from 'react';
import {
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
} from '@material-ui/core';
import { Field } from 'formik';
import { RadioGroup } from 'formik-material-ui';
import { createStyles, makeStyles } from '@material-ui/core/styles';

export type Option = {
  id: string;
  name: string;
};

const useStyles = makeStyles((theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(3),
      marginLeft: 0,
    },
  })
);

function Radios({
  options,
  label,
  fieldName,
  disabled,
}: {
  options?: Option[];
  label?: string;
  fieldName: string;
  disabled?: boolean;
}) {
  const classes = useStyles();
  if (!options) return null;
  return (
    <FormControl
      component='fieldset'
      className={classes.formControl}
      disabled={disabled}
    >
      <FormLabel component='legend'>{label}</FormLabel>
      <Field component={RadioGroup} name={fieldName}>
        {options?.map(({ id, name }) => (
          <FormControlLabel
            key={id}
            value={id}
            control={<Radio />}
            label={name}
          />
        ))}
      </Field>
    </FormControl>
  );
}

export default Radios;
