import React, { PropsWithChildren } from 'react';
import { gql, ApolloQueryResult } from 'apollo-boost';
import { useQuery } from '@apollo/react-hooks';
import { IAdPartner } from './types';
import { useRouteMatch } from 'react-router-dom';
import { Routes } from '../../routes';
import CustomizedProgressBar from '../../components/Loader';

const GET_AD_PARTNER = gql`
  query AdPartner($id: String!) {
    adPartner(where: {id: $id}) {
      id
      name
      notes
      campaignsNumber
      campaigns {
        campaignId
        name
        priority
        targetViewsNumber
        cpm
        maxViewsPerViewer
        startDate
        stopDate
        lastModification
        isActive
      }
    }
  }
`;

interface IParams {
  params: {id: string}
}

interface IAdPartnerData {
  adPartner: IAdPartner[];
}

export interface IWithAdPartner extends IAdPartnerData {
  refetch: (
    variables?: {id: string} | undefined
  ) => Promise<ApolloQueryResult<IAdPartnerData>>;
}

function withAdPartner(
  WrappedComponent: React.FC<IWithAdPartner>,
) {
  return (props: PropsWithChildren<{}>) => {
    const {params} = useRouteMatch(`/${Routes.EditAdPartner}/:id`) as IParams;

    const { loading, error, data, refetch } = useQuery(GET_AD_PARTNER, {
      variables: { id: params.id},
    });
  
    if (loading) return <CustomizedProgressBar />;
    if (error) return <p>Wystąpił Błąd</p>;

    const adPartner = data?.adPartner ?? [];

    return (
      <WrappedComponent
        {...props}
        adPartner={adPartner}
        refetch={refetch}
      />
    );
  };
}

export default withAdPartner;
