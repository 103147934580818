import React, { useEffect } from 'react';
import { firebaseAppAuth, history } from '../App';
import { Routes } from '../routes';
import { Route, RouteProps } from 'react-router-dom';

const withAuth = (
  Component: (props: React.PropsWithChildren<RouteProps>) => JSX.Element
) => (props: React.PropsWithChildren<RouteProps>) => {
  useEffect(() => {
    const unsubscribe = firebaseAppAuth.onAuthStateChanged(authUser => {
      if (!authUser) {
        if (
          window.location.pathname !== `/${Routes.Login}` &&
          window.location.pathname !== ''
        ) {
          if (history) {
            history.push('');
          } else {
            window.location.replace('');
          }
        }
      }
    });

    return unsubscribe;
  });

  return <Component {...props} />;
};

export function PrivateRoute({ component, path }: RouteProps) {
  const Component = withAuth(component as any);
  return <Route path={path} component={Component} />;
}
