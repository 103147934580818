import React from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { NavLink } from 'react-router-dom';
import {
  Typography,
  Button,
} from '@material-ui/core';
import { StyledContainer } from '../../components/StyledContainer';
import { GeneralClasses } from '../../components/GeneralClasses';
import { CustomDialog } from '../../components/CustomDialog';
import { Routes } from '../../routes';
import { useHistory } from "react-router-dom";

const useStyles = makeStyles((theme) => createStyles({
  form: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& h5': {
      marginBottom: 30,
    },
  },
  button: {
    backgroundColor: '#ED1B30',
    color: '#fff',
    height: 56,
    width: 128,
  },
  headerNavlink: {
    display: 'flex',
    alignItems: 'center',
    color: '#333',
    textDecoration: 'none',
    '& h5': {
      marginBottom: 0,
      marginLeft: 20,
    },
  },
  buttonOutlined: {
    color: '#ED1B30',
    borderColor: '#ED1B30',
    height: 56,
    width: 128,
  },
  formControl: {
    margin: theme.spacing(3),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

export const CreateAdPartner: React.FC<{createAdPartnerMutation: any}> = ({ createAdPartnerMutation }) => {
  const classes = useStyles();
  const nameInput = React.useRef<HTMLInputElement>(null);
  const notesInput = React.useRef<HTMLInputElement>(null);
  const [isValidate, setValidate] = React.useState(false);
  const [isError, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const {push} = useHistory();
  const [openModal, setOpenModal] = React.useState(false);

  const handleChange = () => {
    if(nameInput?.current?.value !== '') {
      setValidate(true);
      setError(false);
      setErrorMessage('');
    } else {
      setValidate(false);
      setError(true);
    }
  }

  const handleBlur = () => {
    if(nameInput?.current?.value === '') {
      setError(true);
      setErrorMessage('To pole jest wymagane')
    } else {
      setError(false);
      setErrorMessage('')
    }
  }

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <StyledContainer>
      <NavLink className={classes.headerNavlink} to={`/${Routes.AdPartners}`}>
        <ArrowBackIcon />
        <Typography variant="h5" gutterBottom>Dodaj partnera</Typography>
      </NavLink>
      <form
        noValidate
        autoComplete="off"
        className={classes.form}
        onSubmit={async(e) => {
          e.preventDefault();
          if(isValidate) {
            try {
              await createAdPartnerMutation({
                variables: {
                  name: nameInput?.current?.value ?? '',
                  notes: notesInput?.current?.value ?? '',
                },
              });
              push(`/${Routes.AdPartners}/`);
          } catch (error) {
            setOpenModal(true);
          }} else {
            setError(true);
            setErrorMessage('To pole jest wymagane');
          }
        }}
      >
        <TextField
          id="partner-name"
          label="Nazwa partnera"
          variant="outlined"
          className={GeneralClasses().styledInput}
          inputRef={nameInput}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          error={isError}
          helperText={errorMessage}
        />
        <TextField
          id="notes"
          label="Notatki"
          variant="outlined"
          multiline
          rows={4}
          rowsMax={10}
          className={GeneralClasses().styledInput}
          inputRef={notesInput}
        />
        <Button variant="contained" className={classes.button} type="submit">
          Dodaj
        </Button>
      </form>
      <CustomDialog isModal open={openModal} handleClose={handleModalClose} title="Ups!" description="Wystąpił błąd serwera, lub utraciłeś połączenie z internetem." />
    </StyledContainer>
  );
};

export default CreateAdPartner;
