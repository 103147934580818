import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';

export const CustomDialog: React.FC<{
  open: boolean;
  handleClose: () => void;
  // TODO: make that required
  handleDelete?: () => void;
  title: string;
  description: string;
  isModal?: boolean;
}> = ({
  handleClose, handleDelete, open, title, description, isModal
}) => (
  <Dialog
    open={open}
    onClose={handleClose}
    aria-labelledby="simple-dialog-title"
  >
    <DialogTitle id="simple-dialog-title">
      {title}
    </DialogTitle>
    <DialogContent>
      <DialogContentText>
        {description}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {!isModal ?
      <>
        <Button autoFocus onClick={handleClose} color="primary">
          Anuluj
        </Button>
        <Button onClick={handleDelete || handleClose} color="primary">
          Usuń
        </Button>
      </> :
      <Button autoFocus onClick={handleClose} color="primary">
        OK
      </Button>
      }
    </DialogActions>
  </Dialog>
);

export default CustomDialog;
