import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles, createStyles } from '@material-ui/core';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import withFirebaseAuth from 'react-with-firebase-auth';
import * as firebase from 'firebase/app';
import 'firebase/auth';
import Header from './components/Header';
import { DrawerWithLogic } from './components/Drawer';
import Account from './pages/Account';
import Campaigns from './pages/Campaigns';
import AdPartners from './pages/AdPartners';
import { Login } from './pages/Login';
import EditCampaign from './pages/EditCampaign';
import EditAdPartner from './pages/EditAdPartner';
import CreateAdPartner from './pages/CreateAdPartner';
import { Routes } from './routes';
import { PrivateRoute } from './session/PrivateRoute';
import AddUsers from './pages/AddUsers';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      paddingTop: 66,
      height: '100vh',
    },
    content: {
      flexGrow: 1,
    },
    toolbar: theme.mixins.toolbar,
  })
);

if (!process.env.REACT_APP_FB_APP_CLIENT_CFG) {
  throw new Error('Firebase Client not initialized');
}

const firebaseApp = firebase.initializeApp(
  JSON.parse(process.env.REACT_APP_FB_APP_CLIENT_CFG)
);
export const firebaseAppAuth = firebaseApp.auth();

export const history = createBrowserHistory();

function App() {
  const classes = useStyles();

  return (
    <Router history={history}>
      <div className={classes.root}>
        <CssBaseline />
        <Header />
        <DrawerWithLogic />
        <main className={classes.content}>
          <Route exact path='/' component={Login} />
          <PrivateRoute path={`/${Routes.Account}`} component={Account} />
          <PrivateRoute path={`/${Routes.Campaigns}`} component={Campaigns} />
          <PrivateRoute path={`/${Routes.AdPartners}`} component={AdPartners} />
          <Route path={`/${Routes.Login}`} component={Login} />
          <PrivateRoute
            path={`/${Routes.EditCampaign}`}
            component={EditCampaign}
          />
          <PrivateRoute path={`/${Routes.AddUsers}`} component={AddUsers} />
          <PrivateRoute
            path={`/${Routes.EditAdPartner}/:id`}
            component={EditAdPartner}
          />
          <PrivateRoute
            path={`/${Routes.CreateAdPartner}`}
            component={CreateAdPartner}
          />
        </main>
      </div>
    </Router>
  );
}

export default withFirebaseAuth({ firebaseAppAuth })(App);
