import React from 'react';
import {
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel
} from '@material-ui/core';
import { Checkbox as CheckboxBase } from 'formik-material-ui';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Field, FieldProps } from 'formik';

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      margin: theme.spacing(3),
      marginLeft: 0
    }
  })
);

export type Option = {
  id: string;
  name: string;
};

interface ICheckboxProps extends FieldProps {
  inputProps: {
    id: string;
    value: string;
  };
}

const Checkbox: React.FC<ICheckboxProps> = ({
  field,
  form: { setFieldValue, ...form },
  ...rest
}) => {
  const { value, name } = field;
  if (!value) return null;

  function handleChange(e: React.ChangeEvent<any>) {
    const valInt = e.target.value;
    const newValId = value.indexOf(valInt);
    let newVal = [];
    if (newValId === -1) {
      newVal = [...value, valInt];
    } else {
      value.splice(newValId, 1);
      newVal = value;
    }
    setFieldValue(name || '', newVal);
  }

  const checked = value.indexOf(rest.inputProps.value) !== -1;
  return (
    <CheckboxBase
      {...rest}
      field={{ ...field, checked, onChange: handleChange }}
      form={{ ...form, setFieldValue }}
    />
  );
};

function CheckboxGroup({
  options,
  label,
  fieldName
}: {
  options?: Option[];
  label?: string;
  fieldName: string;
}) {
  const classes = useStyles();
  if (!options) return null;
  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormLabel component="legend">{label}</FormLabel>
      <FormGroup>
        {options?.map(({ id, name }) => (
          <FormControlLabel
            key={id}
            control={
              <Field
                component={Checkbox}
                name={fieldName}
                type="checkbox"
                inputProps={{ id, value: id }}
              />
            }
            label={name}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

export default CheckboxGroup;
