import { firebaseAppAuth, history } from '../App';
import { Routes } from '../routes';

export async function logout() {
  try {
    await fetch(`${process.env.REACT_APP_API}/user/session-logout`, {
      method: 'POST',
      mode: 'cors',
      cache: 'no-cache',
      credentials: 'include',
    });

    await firebaseAppAuth.signOut();
    if (
      window.location.pathname !== `/${Routes.Login}` &&
      window.location.pathname !== ''
    ) {
      history.push('');
    }
  } catch (error) {
    alert(error);
  }
}
