import React, { PropsWithChildren } from 'react';
import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { MutationFunctionOptions, ExecutionResult } from 'react-apollo';
import { IWithCampaign } from './withCampaigns';
import { PaginationProps } from '../../utils/pagination';

const DELETE_CAMPAIGN = gql`
  mutation DeleteCampaign($input: CampaignWhereUniqueInput!) {
    deleteCampaign(where: $input) {
      campaignId
    }
  }
`;

export interface ICampaignProps extends IWithCampaign {
  deleteCampaign: (
    options?: MutationFunctionOptions<any, Record<string, any>>
  ) => Promise<ExecutionResult<any>>;
}

function withDeleteCampaign(
  WrappedComponent: React.FC<ICampaignProps & PaginationProps>
) {
  return (props: PropsWithChildren<IWithCampaign & PaginationProps>) => {
    const [deleteCampaign] = useMutation(DELETE_CAMPAIGN, {
      refetchQueries: ['Campaigns'],
    });
    return <WrappedComponent {...props} deleteCampaign={deleteCampaign} />;
  };
}

export default withDeleteCampaign;
