import * as yup from 'yup';
//@ts-ignore
import { Campaign, PriorityType } from '../Campaigns/types.d.ts';
import { parseDuration } from '../../utils';

export const initialValues = ({
  isActive,
  categoriesPositive,
  categoriesNegative,
  genresPositive,
  genresNegative,
  tagsPositive,
  tagsNegative,
  ageRanges,
  genders,
  parentalStatuses,
  adPartner,
  duration,
  ...campaign
}: Campaign) => {
  return {
    name: '',
    creativeAssetDuration: parseDuration(duration),
    adPartnerId: adPartner ? adPartner.id : null,
    targetViewsNumber: 0,
    maxViewsPerViewer: 0,
    cpm: 0,
    startDate: new Date(),
    stopDate: new Date(),
    isActive: isActive === false ? 'false' : 'true',
    priority: PriorityType.NORMAL,
    categoriesPositive: categoriesPositive.map(({ id }: { id: string }) => id),
    categoriesNegative: categoriesNegative.map(({ id }: { id: string }) => id),
    genresPositive: genresPositive.map(({ id }: { id: string }) => id),
    genresNegative: genresNegative.map(({ id }: { id: string }) => id),
    tagsPositive: tagsPositive.map(({ id }: { id: string }) => id),
    tagsNegative: tagsNegative.map(({ id }: { id: string }) => id),
    ageRanges: ageRanges.map(({ id }: { id: string }) => id),
    genders: genders.map(({ id }: { id: string }) => id),
    // parentalStatuses: parentalStatuses.map(({ id }: { id: string }) => id),
    ...campaign
  };
};

export const ValidationSchema = yup.object().shape({
  targetViewsNumber: yup
    .number()
    .min(0)
    .required('Pole wymagane'),
  maxViewsPerViewer: yup
    .number()
    .min(0)
    .required('Pole wymagane'),
  priority: yup
    .mixed<keyof typeof PriorityType>()
    .oneOf(Object.values(PriorityType))
    .required('Pole wymagane'),
  cpm: yup
    .string()
    .matches(
      /^[0-9]{1,5}(\.[0-9]{1,4}){0,1}$/,
      'Wymagana liczba dodatnia, max 5 cyfr przed i max 4 po przecinku'
    )
    .required('Pole wymagane'),
  startDate: yup
    .date()
    .typeError('Wymagana data w formacie dd/mm/yyyy')
    .required('Wymagana data w formacie dd/mm/yyyy'),
  stopDate: yup
    .date()
    .typeError('Wymagana data w formacie dd/mm/yyyy')
    .required('Wymagana data w formacie dd/mm/yyyy')
});
