import { useQuery } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React from 'react';
import CheckboxGroup, { Option } from './Checkbox';
import { AgeRange } from '../types';
import { parseAgeRange } from '../../../../utils';

const GET_AGE_RANGES = gql`
  query AgeRanges {
    ageRanges {
      id
      min
      max
    }
  }
`;

interface IAgeRangesData {
  ageRanges?: AgeRange[];
}

function AgeRanges(props: { label: string; fieldName: string }) {
  const { data } = useQuery<IAgeRangesData, {}>(GET_AGE_RANGES);
  const { ageRanges } = data ?? { ageRanges: [] };

  const options = ageRanges?.map(({ id, min, max }) => ({
    id: id.toString(),
    name: parseAgeRange(min, max),
  })) as Option[];

  return <CheckboxGroup {...props} options={options} />;
}

export default AgeRanges;
