export function parseDuration(duration?: number[]): string {
  if (!duration || isNaN(duration[0])) return "-";
  if (duration[0] < 60) return `${duration[0] % 60}s`;
  if (duration[0] < 3600)
    return `${Math.floor(duration[0] / 60)}min ${duration[0] % 60}s`;

  return `${Math.floor(duration[0] / 3600)}h ${Math.floor(
    (duration[0] / 60) % 60
  )}min ${duration[0] % 60}s`;
}
