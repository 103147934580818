export enum Routes {
  Account = 'account',
  AdPartners = 'ad-partners',
  Login = 'login',
  EditCampaign = 'campaign/:id/edit',
  AddUsers = 'campaign/:id/add-users',
  EditAdPartner = 'ad-partner/edit',
  CreateAdPartner = 'ad-partner/add',
  Campaigns = 'campaigns',
}
