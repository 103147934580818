import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Toolbar, IconButton, AppBar } from '@material-ui/core';
import { NavLink, useLocation } from 'react-router-dom';
import logo from '../assets/logo192.png';
import { Routes } from '../routes';

const useStyles = makeStyles(theme =>
  createStyles({
    appBar: {
      zIndex: theme.zIndex.drawer + 1
    }
  })
);

export default function Header() {
  const classes = useStyles();
  const { pathname } = useLocation();

  const url =
    pathname === '/' || pathname === `/${Routes.Login}`
      ? pathname
      : `/${Routes.AdPartners}`;

  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <NavLink to={url}>
          <IconButton edge="start" color="inherit" aria-label="menu">
            <img src={logo} alt="logo" />
          </IconButton>
        </NavLink>
      </Toolbar>
    </AppBar>
  );
}
