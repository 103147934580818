import { Field } from 'formik';
import { TextField } from 'formik-material-ui';
import React from 'react';
import { Alert } from '@material-ui/lab';
import { GeneralClasses } from '../../components/GeneralClasses';
import { IsActive, Priority } from './SelectorsWithData';
import AdPartners from './SelectorsWithData/Partners';

export const GeneralInfo = ({
  isAssetBlocked,
}: {
  isAssetBlocked: boolean;
}) => {
  return (
    <>
      <Field
        component={TextField}
        name='name'
        label='Nazwa kampanii'
        InputProps={{
          readOnly: true,
        }}
        variant='outlined'
        className={GeneralClasses().styledInput}
      />
      <Field
        component={TextField}
        name='creativeAssetDuration'
        label='Długość materiału video'
        InputProps={{
          readOnly: true,
        }}
        variant='outlined'
        className={GeneralClasses().styledInput}
      />
      <AdPartners />
      <Priority fieldName='priority' label='Priorytet' />
      {isAssetBlocked && (
        <Alert severity='warning'>
          Assety dla danej kampanii nie są aktywne!
        </Alert>
      )}
      <IsActive
        fieldName='isActive'
        label='Aktywna'
        disabled={isAssetBlocked}
      />
    </>
  );
};

export default GeneralInfo;
