import React from 'react';
import TextField from '@material-ui/core/TextField';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { NavLink } from 'react-router-dom';
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { StyledContainer } from '../../components/StyledContainer';
import { InlineWrapper } from '../../components/InlineWrapper';
import { CustomDialog } from '../../components/CustomDialog';
import { GeneralClasses } from '../../components/GeneralClasses';
import { Routes } from '../../routes';
import { Campaign } from '../../components/Campaign';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
  createStyles({
    form: {
      marginTop: 40,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      '& h5': {
        marginBottom: 30,
      },
    },
    button: {
      backgroundColor: '#ED1B30',
      color: '#fff',
      height: 56,
      width: 128,
    },
    headerNavlink: {
      display: 'flex',
      alignItems: 'center',
      color: '#333',
      textDecoration: 'none',
      '& h5': {
        marginBottom: 0,
        marginLeft: 20,
      },
    },
    buttonOutlined: {
      color: '#ED1B30',
      borderColor: '#ED1B30',
      height: 56,
      width: 128,
    },
    formControl: {
      margin: theme.spacing(3),
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

export const EditAdPartner: React.FC<any> = ({
  adPartner,
  editAdPartnerMutation,
  deleteAdPartnerMutation,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openPartnerDialog, setOpenPartnerDialog] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);
  const [isValidate, setValidate] = React.useState(true);
  const [isError, setError] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');
  const { push } = useHistory();

  const handleChange = () => {
    if (nameInput?.current?.value !== '') {
      setValidate(true);
      setError(false);
      setErrorMessage('');
    } else {
      setValidate(false);
      setError(true);
    }
  };

  const handleBlur = () => {
    if (nameInput?.current?.value === '') {
      setError(true);
      setErrorMessage('To pole jest wymagane');
    } else {
      setError(false);
      setErrorMessage('');
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    console.log(anchorEl);
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const handleClickPartnerDialogOpen = () => {
    if (adPartner.campaignsNumber === 0) {
      setOpenPartnerDialog(true);
    } else {
      setOpenModal(true);
    }
  };

  const handleErrorModalClose = () => {
    setOpenErrorModal(false);
  };

  const handlePartnerDialogClose = () => {
    setAnchorEl(null);
    setOpenPartnerDialog(false);
  };

  const handleDelete = async () => {
    setOpenPartnerDialog(false);
    try {
      await deleteAdPartnerMutation({ variables: { id: adPartner.id } });
      push(`/${Routes.AdPartners}/`);
    } catch (error) {
      setOpenErrorModal(true);
    }
  };

  const classes = useStyles();
  const nameInput = React.useRef<HTMLInputElement>(null);
  const notesInput = React.useRef<HTMLInputElement>(null);

  return (
    <StyledContainer>
      <NavLink className={classes.headerNavlink} to={`/${Routes.AdPartners}`}>
        <ArrowBackIcon />
        <Typography variant='h5' gutterBottom>
          Partner
        </Typography>
      </NavLink>
      <form
        noValidate
        autoComplete='off'
        className={classes.form}
        onSubmit={async (e) => {
          e.preventDefault();
          if (isValidate) {
            try {
              await editAdPartnerMutation({
                variables: {
                  id: adPartner.id,
                  name: nameInput?.current?.value ?? '',
                  notes: notesInput?.current?.value ?? '',
                },
              });
              push(`/${Routes.AdPartners}/`);
            } catch (error) {
              setOpenErrorModal(true);
            }
          }
        }}
      >
        <TextField
          id='partner-name'
          label='Nazwa partnera'
          defaultValue={adPartner.name}
          variant='outlined'
          className={GeneralClasses().styledInput}
          inputRef={nameInput}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          error={isError}
          helperText={errorMessage}
        />
        <TextField
          id='notes'
          label='Notatki'
          defaultValue={adPartner.notes}
          variant='outlined'
          multiline
          rows={4}
          rowsMax={10}
          className={GeneralClasses().styledInput}
          inputRef={notesInput}
        />
        <Typography variant='h5' gutterBottom>
          Kampanie
        </Typography>
        <TableContainer>
          {adPartner.campaigns.length !== 0 ? (
            <>
              <Table aria-label='simple table'>
                <TableHead>
                  <TableRow>
                    <TableCell>Nazwa kampanii</TableCell>
                    <TableCell align='left'>Priorytet</TableCell>
                    <TableCell align='left'>Wyświetlenia cel</TableCell>
                    <TableCell align='left'>CPM</TableCell>
                    <TableCell align='left'>Capping</TableCell>
                    <TableCell align='left'>Czas</TableCell>
                    <TableCell align='left'>Ostatnia modyfikacja</TableCell>
                    <TableCell align='left'>Aktywna</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {adPartner.campaigns &&
                    adPartner.campaigns.map((campaign: any) => {
                      const lastModification = moment(
                        campaign.lastModification
                      ).format('YYYY-MM-DD HH:mm');
                      return (
                        <Campaign
                          key={campaign.id}
                          name={campaign.name}
                          prio={campaign.priority}
                          viewsGoal={campaign.targetViewsNumber}
                          cpm={campaign.cpm}
                          viewsLimit={campaign.maxViewsPerViewer}
                          startDate={campaign.startDate}
                          endDate={campaign.stopDate}
                          lastModification={lastModification}
                          isActive={campaign.isActive}
                          handleClick={handleClick}
                          id={campaign.id}
                          disabled
                          isAssetBlocked={campaign.isAssetBlocked}
                        />
                      );
                    })}
                </TableBody>
              </Table>
            </>
          ) : (
            <Typography component='p'>
              Partner nie ma jeszcze przypisanych kampanii
            </Typography>
          )}
        </TableContainer>
        <InlineWrapper>
          <Button
            component={NavLink}
            to={`/${Routes.AdPartners}`}
            variant='outlined'
            className={classes.buttonOutlined}
          >
            Anuluj
          </Button>
          <Button variant='contained' className={classes.button} type='submit'>
            Zapisz
          </Button>
        </InlineWrapper>
        <Button onClick={handleClickPartnerDialogOpen}>Usuń partnera</Button>
      </form>
      <CustomDialog
        open={openPartnerDialog}
        handleClose={handlePartnerDialogClose}
        handleDelete={handleDelete}
        title='Usuń partnera'
        description='Czy na pewno chcesz usunąć partnera?'
      />
      <CustomDialog
        isModal
        open={openModal}
        handleClose={handleModalClose}
        title='Usuń partnera'
        description='Nie możesz usunąć partnera, który posiada przypisane kampanie.'
      />
      <CustomDialog
        isModal
        open={openErrorModal}
        handleClose={handleErrorModalClose}
        title='Ups!'
        description='Wystąpił błąd serwera, lub utraciłeś połączenie z internetem.'
      />
    </StyledContainer>
  );
};

export default EditAdPartner;
