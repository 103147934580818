import { IconButton, TableCell, TableRow, Tooltip } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import WarningIcon from '@material-ui/icons/Warning';
import React from 'react';
import moment from 'moment';
import GeneralClasses from './GeneralClasses';
import { parseDuration } from '../utils';

export const Campaign: React.FC<{
  name: string;
  partner?: string;
  prio: string;
  viewsGoal: number;
  cpm: number;
  viewsLimit: number;
  startDate: string;
  endDate: string;
  lastModification: string;
  isActive: boolean;
  handleClick: any;
  id: number;
  disabled?: boolean;
  duration?: number[];
  isAssetBlocked: boolean;
}> = ({
  name,
  partner,
  prio,
  viewsGoal,
  cpm,
  viewsLimit,
  startDate,
  endDate,
  lastModification,
  isActive,
  handleClick,
  id,
  disabled,
  duration,
  isAssetBlocked,
}) => {
  const { inlineParagraph } = GeneralClasses();
  return (
    <TableRow key={name}>
      {!disabled && (
        <TableCell component='th' scope='row'>
          <IconButton
            aria-label='more'
            aria-controls='long-menu'
            aria-haspopup='true'
            onClick={(e) => handleClick(e, id)}
          >
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      )}
      <TableCell component='th' scope='row'>
        {name}
      </TableCell>
      <TableCell align='left'>
        {isAssetBlocked && (
          <Tooltip title='Assety dla danej kampanii nie są aktywne'>
            <WarningIcon style={{ color: '#ffc107' }} />
          </Tooltip>
        )}
      </TableCell>
      {partner && <TableCell align='left'>{partner}</TableCell>}
      <TableCell align='left'>{prio}</TableCell>
      <TableCell align='left'>{viewsGoal}</TableCell>
      <TableCell align='left'>{cpm}</TableCell>
      <TableCell align='left'>{viewsLimit}</TableCell>
      <TableCell align='left'>{parseDuration(duration)}</TableCell>
      <TableCell align='left'>
        <div className={inlineParagraph}>
          {moment(startDate).format('DD-MM-YYYY HH:mm')}
        </div>{' '}
        -
        <br />
        <div className={inlineParagraph}>
          {moment(endDate).format('DD-MM-YYYY HH:mm')}
        </div>
      </TableCell>
      <TableCell className={inlineParagraph} align='left'>
        {moment(lastModification).format('DD-MM-YYYY HH:mm')}
      </TableCell>
      <TableCell align='left'>{isActive ? 'Tak' : 'Nie'}</TableCell>
    </TableRow>
  );
};

export default Campaign;
