import React, { useState } from 'react';
import _ from 'lodash';

import { EnhancedFile } from './UploadFileButton';

interface IResponse {
  error: boolean;
  message: string;
  details?: string;
}
export interface IAddUsersProps {
  onAddUsers: (file: EnhancedFile) => Promise<void>;
  isLoading: boolean;
  response: IResponse;
}

function withAddUsers(WrappedComponent: React.FC<IAddUsersProps>) {
  return (props: {}) => {
    const [isLoading, setLoading] = useState<boolean>(false);
    const [response, setResponse] = useState<IResponse>({
      error: false,
      message: '',
    });

    const onAddUsers = async (file: EnhancedFile) => {
      const id = _.get(props, 'match.params.id', undefined);
      if (!id) {
        return;
      }
      setLoading(true);
      const camaign_id = id;
      const formData = new FormData();
      formData.append('file', file);

      try {
        const res = await fetch(
          `${process.env.REACT_APP_API}/target-users/upload/${camaign_id}`,
          {
            method: 'POST',
            body: formData,
            credentials: 'include',
          }
        );
        const details = (await res.json()).message as string;
        const success = res.ok;

        setResponse({
          error: !success,
          message: success
            ? 'Sukces! Plik został poprawnie wysłany'
            : 'Błąd! Nie udało się wysłać pliku',
          details,
        });
      } catch (error) {
        setResponse({
          error: true,
          message: 'Błąd! Nie udało się wysłać pliku',
        });
      }
      setLoading(false);
    };

    return (
      <WrappedComponent
        {...props}
        onAddUsers={onAddUsers}
        isLoading={isLoading}
        response={response}
      />
    );
  };
}

export default withAddUsers;
