import React, { PropsWithChildren } from 'react';
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';
//@ts-ignore
import { ICampaign } from './types.d.ts';
import { RouteComponentProps } from 'react-router-dom';
import CustomizedProgressBar from '../../components/Loader';

const GET_CAMPAIGNS = gql`
  query Campaign($input: CampaignWhereUniqueInput!) {
    campaign(where: $input) {
      adPartner {
        id
        name
      }
      campaignId
      isActive
      priority
      maxViewsPerViewer
      name
      startDate
      stopDate
      targetViewsNumber
      cpm
      lastModification
      duration
      isAssetBlocked
      genders {
        id
      }
      tagsPositive {
        id
      }
      tagsNegative {
        id
      }
      categoriesPositive {
        id
      }
      categoriesNegative {
        id
      }
      genresPositive {
        id
      }
      genresNegative {
        id
      }
      ageRanges {
        id
      }
      parentalStatuses {
        id
      }
    }
  }
`;

interface ICampaignsData {
  campaign: ICampaign;
}

function withCampaign(WrappedComponent: React.FC<ICampaignsData>) {
  return (props: PropsWithChildren<RouteComponentProps<{ id: string }>>) => {
    const { loading, error, data } = useQuery<ICampaignsData, {}>(
      GET_CAMPAIGNS,
      {
        variables: { input: { id: props.match.params.id } },
      }
    );

    if (loading) return <CustomizedProgressBar />;
    if (error) return <p>Wystąpił Błąd</p>;

    if (!data) return null;
    const { campaign } = data;
    return <WrappedComponent {...props} campaign={campaign} />;
  };
}

export default withCampaign;
