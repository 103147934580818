import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import React, { PropsWithChildren } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { MutationFunctionOptions } from 'react-apollo';
import { ExecutionResult } from 'graphql';


const UPDATE_CAMPAIGN = gql`
  mutation UpdateCampaign(
    $where: CampaignWhereUniqueInput!
    $input: CampaignUpdateInput!
  ) {
    updateCampaign(where: $where, data: $input) {
      adPartner {
        id
        name
      }
      campaignId
      name
      viewsNumber
      isActive
      priority
      maxViewsPerViewer
      startDate
      stopDate
      targetViewsNumber
      lastModification
      genders {
        id
      }
      tagsPositive {
        id
      }
      tagsNegative {
        id
      }
      categoriesPositive {
        id
      }
      categoriesNegative {
        id
      }
      genresPositive {
        id
      }
      genresNegative {
        id
      }
      ageRanges {
        id
      }
      parentalStatuses {
        id
      }
    }
  }
`;

function withUpdateCampaign(
  WrappedComponent: React.FC<{
    updateCampaign: (
      options?: MutationFunctionOptions<{}, {}> | undefined
    ) => Promise<ExecutionResult<{}>>;
  }>
) {
  return (props: PropsWithChildren<RouteComponentProps<{ id: string }>>) => {
    const [updateCampaign] = useMutation<{}, {}>(UPDATE_CAMPAIGN, {
      refetchQueries: ['Campaigns']
    });

    return <WrappedComponent {...props} updateCampaign={updateCampaign} />;
  };
}

export default withUpdateCampaign;
