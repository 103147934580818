import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Button, Typography, Menu, MenuItem } from '@material-ui/core';
import { Routes } from '../../routes';
import { StyledContainer } from '../../components/StyledContainer';
import { InlineWrapper } from '../../components/InlineWrapper';
import { GeneralClasses } from '../../components/GeneralClasses';
import AdPartner from '../../components/AdPartner';
import { CustomDialog } from '../../components/CustomDialog';
import { IAdPartnerProps } from './withDeleteAdPartner';
import { useHistory } from 'react-router-dom';
import SearchField from '../../components/SearchField';
import { HeadCellProps } from '../../components/TableLabels';
import { PaginationProps } from '../../utils/pagination';
import TableView from '../../components/TableView';
import CustomizedProgressBar from '../../components/Loader';

export const AdPartners: React.FC<IAdPartnerProps & PaginationProps> = ({
  adPartners,
  deleteAdPartner,
  page,
  rowsPerPage,
  refetch,
  totalRows,
  loading,
  ...restProps
}) => {
  const { push } = useHistory();

  const [anchorEl, setAnchorEl] = useState<null | {
    el: HTMLElement;
    id: string;
    campaignsNumber: number
  }>(null);

  const [isMenuVisible, setMenuVisibility] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);

  const headCells: HeadCellProps[] = [
    { id: 'name', label: 'Nazwa Partnera', isSortable: true, align: 'inherit' },
    { id: 'campaignsNumber', label: 'Liczba Kampanii', isSortable: true },
    { id: 'notes', label: 'Notatki', isSortable: false },
  ];

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    campaignsNumber: number
  ) => {
    setAnchorEl({ el: event.currentTarget, id, campaignsNumber });
  };

  const handleMenuOpen = () => {
    if (!anchorEl) return;
    if (anchorEl.campaignsNumber === 0) {
      setMenuVisibility(true);
    } else {
      setAnchorEl(null);
      setOpenModal(true);
    }
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuVisibility(false);
  };

  const handleErrorModalClose = () => {
    setOpenErrorModal(false);
  };

  const handleDelete = async () => {
    setAnchorEl(null);
    setMenuVisibility(false);
    if (!anchorEl) return;
    try {
      await deleteAdPartner({ variables: { id: anchorEl.id } });
      push(`/${Routes.AdPartners}/`);
    } catch (error) {
      setOpenErrorModal(true);
    }
  };

  const handleEdit = () => {
    if (!anchorEl) return;
    push(`/${Routes.EditAdPartner}/` + anchorEl.id);
    handleMenuClose();
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  return (
    <StyledContainer>
      <InlineWrapper>
        <Typography variant='h5' gutterBottom>
          Lista partnerów
        </Typography>
        <Button
          variant='outlined'
          className={GeneralClasses().buttonOutlined}
          component={NavLink}
          to={`/${Routes.CreateAdPartner}`}
        >
          Dodaj partnera
        </Button>
        <SearchField refetchQuery={refetch} />
      </InlineWrapper>
      { loading ? <CustomizedProgressBar /> :
      <TableView
        headCells={headCells}
        totalRowsQuantity={totalRows}
        tableBody={
          adPartners &&
          adPartners
            .map((adPartner) => (
              <AdPartner
                key={adPartner.id}
                id={adPartner.id}
                name={adPartner.name}
                campaignsNumber={adPartner.campaignsNumber}
                notes={adPartner.notes}
                handleClick={handleClick}
              />
            ))
        }
        page={page}
        rowsPerPage={rowsPerPage}
        {...restProps}
      />}
      <Menu
        id='simple-menu'
        anchorEl={anchorEl && anchorEl.el}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleEdit}>Edytuj</MenuItem>
        <MenuItem onClick={handleMenuOpen}>Usuń</MenuItem>
      </Menu>
      <CustomDialog
        open={isMenuVisible}
        handleClose={handleMenuClose}
        handleDelete={handleDelete}
        title='Usuń partnera'
        description='Czy na pewno chcesz usunąć partnera?'
      />
      <CustomDialog
        isModal
        open={openModal}
        handleClose={handleModalClose}
        title='Usuń partnera'
        description='Nie możesz usunąć partnera, który posiada przypisane kampanie.'
      />
      <CustomDialog
        isModal
        open={openErrorModal}
        handleClose={handleErrorModalClose}
        title='Ups!'
        description='Wystąpił błąd serwera, lub utraciłeś połączenie z internetem.'
      />
    </StyledContainer>
  );
};

export default AdPartners;
