import React from 'react';
import {
  TableCell,
  TableRow,
  IconButton,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';

export const AdPartner: React.FC<{
  id: string,
  name: string,
  campaignsNumber: number,
  notes?: string,
  handleClick: any,
}> = ({
  id,
  name,
  campaignsNumber,
  notes,
  handleClick,
}) => (
  <TableRow key={name}>
    <TableCell component="th" scope="row">
      <IconButton
        aria-label="more"
        aria-controls="long-menu"
        aria-haspopup="true"
        onClick={(e) => handleClick(e, id, campaignsNumber)}
      >
        <MoreVertIcon />
      </IconButton>
    </TableCell>
    <TableCell component="th" scope="row">
      {name}
    </TableCell>
    <TableCell align="left">{campaignsNumber}</TableCell>
    <TableCell align="left">{notes}</TableCell>
  </TableRow>
);

export default AdPartner;
