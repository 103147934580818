import gql from 'graphql-tag';
import { withParsedData } from '../withParsedData';
import Autocompletes from './Autocomplete';

const GET_TAGS = gql`
  query {
    options: tags {
      id
      name
    }
  }
`;

const Tags = withParsedData({
  QUERY: GET_TAGS,
  Component: Autocompletes
});

export default Tags;
