export interface ICampaign {
  campaignId: number;
  adPartner?: IAdPartner;
  name: string;
  priority: PriorityType;
  targetViewsNumber: number;
  cpm: number;
  maxViewsPerViewer: number;
  startDate: string;
  stopDate: string;
  isActive: boolean;
  // creativeAsset?: CreativeAsset;
  lastModification: string;
  duration?: number[];
  isAssetBlocked: boolean;
  // ageRange?: AgeRange[];
  // categoriesPositive?: Category[];
  // categoriesNegative?: Category[];
  // genresPositive?: Genre[];
  // genresNegative?: Genre[];
  // tagsPositive?: Tag[];
  // tagsNegative?: Tag[];
  // genders?: Gender[];
  // parentalStatuses?: ParentalStatus[];
}

export interface CreativeAsset {
  universalAdId: number;
  adServingId: number;
  duration: number;
}

export interface IAdPartner {
  id: number;
  name: string;
  campaignsNumber: number;
  notes?: string;
  campaigns?: ICampaign[];
}

export enum PriorityType {
  NORMAL = 'NORMAL',
  ASAP = 'ASAP',
}
