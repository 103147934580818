import gql from 'graphql-tag';
import { withParsedData } from '../withParsedData';
import Autocompletes from './Autocomplete';

const GET_GENRES = gql`
  query {
    options: genres {
      id
      name
    }
  }
`;

const Genres = withParsedData({
  QUERY: GET_GENRES,
  Component: Autocompletes
});

export default Genres;
