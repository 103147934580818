import { Button, Menu, MenuItem, Typography } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Campaign } from '../../components/Campaign';
import { GeneralClasses } from '../../components/GeneralClasses';
import { InlineWrapper } from '../../components/InlineWrapper';
import { StyledContainer } from '../../components/StyledContainer';
import { Routes } from '../../routes';
import { CustomDialog } from '../../components/CustomDialog';
import { ICampaign } from './types';
import { ICampaignProps } from './withDeleteCampaign';
import SearchField from '../../components/SearchField';
import { HeadCellProps } from '../../components/TableLabels';
import { PaginationProps } from '../../utils/pagination';
import TableView from '../../components/TableView';
import CustomizedProgressBar from '../../components/Loader';

export const Campaigns: React.FC<ICampaignProps & PaginationProps> = ({
  campaigns,
  refetch,
  deleteCampaign,
  page,
  rowsPerPage,
  totalRows,
  loading,
  ...restProps
}) => {
  const headCells: HeadCellProps[] = [
    { id: 'name', label: 'Nazwa kampanii', isSortable: true, align: 'inherit' },
    { id: 'assets', label: 'Assety', isSortable: false },
    { id: 'adPartner', label: 'Partner', isSortable: true },
    { id: 'priority', label: 'Priorytet', isSortable: true },
    { id: 'targetViewsNumber', label: 'Wyświetlenia cel', isSortable: true },
    { id: 'cpm', label: 'CPM', isSortable: true },
    { id: 'maxViewsPerViewer', label: 'Capping', isSortable: true },
    { id: 'duration', label: 'Długość materiału video', isSortable: true },
    { id: 'time', label: 'Czas', isSortable: false },
    { id: 'lastModification', label: 'Ostatnia modyfikacja', isSortable: true },
    { id: 'isActive', label: 'Aktywna', isSortable: true },
  ];

  const [anchorEl, setAnchorEl] = React.useState<null | {
    el: HTMLElement;
    id: number;
  }>(null);
  const [open, setOpen] = React.useState(false);
  const [openErrorModal, setOpenErrorModal] = React.useState(false);

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: number
  ) => {
    setAnchorEl({ el: event.currentTarget, id });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleErrorModalClose = () => {
    setOpenErrorModal(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const handleDelete = async () => {
    if (!anchorEl) return;
    try {
      await deleteCampaign({ variables: { input: { id: anchorEl.id } } });
    } catch (error) {
      setOpenErrorModal(true);
    }
    handleClose();
  };

  return (
    <StyledContainer>
      <Menu
        id='simple-menu'
        anchorEl={anchorEl && anchorEl.el}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem
          component={NavLink}
          to={(([a, b]) => ['/', a, anchorEl?.id, b].join(''))(
            Routes.EditCampaign.split(':id')
          )}
          onClick={handleClose}
        >
          Edytuj
        </MenuItem>
        <MenuItem onClick={handleClickOpen}>Usuń</MenuItem>
        <MenuItem
          component={NavLink}
          to={(([a, b]) => ['/', a, anchorEl?.id, b].join(''))(
            Routes.AddUsers.split(':id')
          )}
          onClick={handleClose}
        >
          Dodaj użytkowników
        </MenuItem>
      </Menu>
      <CustomDialog
        open={open}
        handleClose={handleClose}
        title='Usuń kampanię'
        description='Czy na pewno chcesz usunąć kampanię?'
        handleDelete={handleDelete}
      />
      <InlineWrapper>
        <Typography variant='h5' gutterBottom>
          Lista kampanii
        </Typography>
        <Button
          variant='outlined'
          className={GeneralClasses().buttonOutlined}
          onClick={() => refetch()}
        >
          Odśwież
        </Button>
        <SearchField refetchQuery={refetch} />
      </InlineWrapper>
      { loading ? <CustomizedProgressBar /> :
      <TableView
        headCells={headCells}
        totalRowsQuantity={totalRows}
        tableBody={
          campaigns &&
          campaigns
            .map((campaign: ICampaign) => (
              <Campaign
                name={campaign.name}
                partner={campaign?.adPartner?.name || '-'}
                prio={campaign.priority}
                viewsGoal={campaign.targetViewsNumber}
                cpm={campaign.cpm}
                viewsLimit={campaign.maxViewsPerViewer}
                startDate={campaign.startDate}
                endDate={campaign.stopDate}
                lastModification={campaign.lastModification}
                isActive={campaign.isActive}
                handleClick={handleClick}
                key={campaign.campaignId}
                id={campaign.campaignId}
                duration={campaign.duration}
                isAssetBlocked={campaign.isAssetBlocked}
              />
            ))
        }
        page={page}
        rowsPerPage={rowsPerPage}
        {...restProps}
      />
      }
      <CustomDialog
        isModal
        open={openErrorModal}
        handleClose={handleErrorModalClose}
        title='Ups!'
        description='Wystąpił błąd serwera, lub utraciłeś połączenie z internetem.'
      />
    </StyledContainer>
  );
};

export default Campaigns;
