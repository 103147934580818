import withCampaigns from './withCampaigns';
import View from './View';
import withDeleteCampaign from './withDeleteCampaign';
import withPagination from '../../utils/pagination';

const initialOrder = 'name';
const initialSortingOrder = 'desc';

const Campagains = withPagination(
  initialOrder,
  initialSortingOrder
)(withCampaigns(withDeleteCampaign(View)));

export default Campagains;
