import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Typography } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { NavLink } from 'react-router-dom';
import { Formik, Form } from 'formik';

import { StyledContainer } from '../../components/StyledContainer';
import { InlineWrapper } from '../../components/InlineWrapper';
import { Routes } from '../../routes';
import UploadFileButton, { EnhancedFile } from './UploadFileButton';
import { ValidationSchema } from './formikConfig';
import { IAddUsersProps } from './withAddUsers';
import CustomizedProgressBar from '../../components/Loader';

const useStyles = makeStyles({
  form: {
    marginTop: 40,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    '& h5': {
      marginBottom: 50,
    },
  },
  button: {
    backgroundColor: '#ED1B30',
    color: '#fff',
    height: 56,
    width: 128,
  },
  headerNavlink: {
    display: 'flex',
    alignItems: 'center',
    color: '#333',
    textDecoration: 'none',
    '& h5': {
      marginBottom: 0,
      marginLeft: 20,
    },
  },
  response: {
    fontSize: '1rem',
    lineHeight: 1.334,
  },
  error: {
    color: '#ED1B30',
  },
  details: {
    fontSize: '0.8rem',
    display: 'block',
  },
});

export const AddUsers: React.FC<IAddUsersProps> = ({
  onAddUsers,
  isLoading,
  response,
}) => {
  const classes = useStyles();

  const handleSubmit = ({ file }: { file?: EnhancedFile }) => {
    if (!file) {
      return;
    }
    onAddUsers(file);
  };

  return (
    <>
      {isLoading && <CustomizedProgressBar />}
      <StyledContainer>
        <NavLink className={classes.headerNavlink} to={`/${Routes.Campaigns}`}>
          <ArrowBackIcon />
          <Typography variant='h5' gutterBottom>
            Kampania
          </Typography>
        </NavLink>
        <Formik
          enableReinitialize
          initialValues={{}}
          validationSchema={ValidationSchema}
          onSubmit={handleSubmit}
        >
          {({ submitForm, ...restFormikProps }) => (
            <Form autoComplete='off' noValidate className={classes.form}>
              <Typography variant='h5' gutterBottom>
                Dodaj użytkowników
              </Typography>
              <InlineWrapper>
                <UploadFileButton {...restFormikProps} />
                <Button
                  onClick={submitForm}
                  variant='contained'
                  className={classes.button}
                  disabled={isLoading}
                >
                  Wyślij
                </Button>
              </InlineWrapper>
              {response && (
                <Typography
                  component={'p'}
                  className={`${classes.response} ${
                    response.error && classes.error
                  }`}
                >
                  {response.message}
                  <span className={classes.details}>{response.details}</span>
                </Typography>
              )}
            </Form>
          )}
        </Formik>
      </StyledContainer>
    </>
  );
};

export default AddUsers;
