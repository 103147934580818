import gql from 'graphql-tag';
import { withParsedData } from '../withParsedData';
import Checkbox from './Checkbox';

const GET_GENDERS = gql`
  query {
    options: genders {
      id
      name
    }
  }
`;

const Genders = withParsedData({ QUERY: GET_GENDERS, Component: Checkbox });

export default Genders;
